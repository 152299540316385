import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  h1 {
    margin: 12% 0 0 0;
  }
  h2 {
    margin: 2% 0;
  }
`

const HomeButton = styled(Link)`
  background: black;
  border-radius: 24px;
  color: white;
  padding: 18px;

  :hover {
    background: white;
    color: black;
    border: 1px solid black;
  }
`

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <h1>Hmm... </h1>
          <h2>It doesn't appear there's anything here?</h2>
          <iframe
            src="https://giphy.com/embed/hEc4k5pN17GZq"
            width="480"
            height="240"
            frameBorder="0"
            class="giphy-embed"
            allowFullScreen
          />
          <HomeButton to="/">Go back to my homepage</HomeButton>
        </Container>
      </Layout>
    )
  }
}

export default NotFoundPage
